<template>
  <EditAssetTypeStyled> Edit </EditAssetTypeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const EditAssetTypeStyled = styled('div')``

export default {
  components: {
    EditAssetTypeStyled,
  },
}
</script>
